import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { MethodCard, Layout, HeadingCard } from "../components"
import * as S from "./styles/__methods.styles"

const Methods = (data) => {
  const methods = data.data.allContentfulMethod.nodes
  const pageDetails = data.data.contentfulWebsiteDetails.methodsPage
  return (
    <Layout>
      <S.Grid>
        <HeadingCard
          heading={pageDetails?.heading}
          description={pageDetails?.description.description}
        />
        <S.CardsWrapper lessThan4={methods.length < 4}>
          {methods.map((method) => {
            return (
              <MethodCard
                link={`methods/${method.slug}`}
                key={method.id}
                title={method.title}
                tags={method.tags}
                img={method.image}
              />
            )
          })}
        </S.CardsWrapper>
      </S.Grid>
    </Layout>
  )
}

Methods.propTypes = {
  data: PropTypes.shape({
    data: PropTypes.shape({
      contentfulWebsiteDetails: PropTypes.objectOf(PropTypes.string),
      allContentfulMethod: PropTypes.arrayOf(PropTypes.object),
    }),
  }).isRequired,
}

export default Methods

export const pageQuery = graphql`
  query {
    contentfulWebsiteDetails {
      methodsPage {
        heading
        description {
          description
        }
      }
    }
    allContentfulMethod {
      nodes {
        id
        slug
        title
        tags
        image {
          description
          fluid(maxWidth: 288, quality: 100) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
