import media from "styled-media-query"
import styled, { css } from "styled-components"
import { Banner, HeadingCard } from "../../components"

// medium is 768px
// 414px is the largest mobile screen

export const CardsWrapper = styled.div`
  display: grid;
  grid-row-gap: 1rem;
  padding 1rem;
  grid-template-rows: 315px;
  grid-auto-rows: 315px;
  ${media.greaterThan("414px")`
    padding: 2rem 2rem; 
    grid-template-columns: ${props => props.lessThan4 ? 'repeat(auto-fit, minmax(20%, 224px))' : 'repeat(auto-fit, minmax(225px, 1fr))'} ;
    grid-template-rows: minmax(315px, 1fr);
    grid-gap: 2rem;
  `}
  ${media.lessThan("medium")`
    ${props => props.lessThan4 && css`
        justify-content: center;
        grid-column-gap: 1rem;
      `}
  `}
`

export const Grid = styled.div`
  display: grid;
  grid-template-rows: auto;
  ${HeadingCard}, ${Banner} {
    grid-column: 1 / -1;
    border-bottom: ${props => props.theme.borderStyle};
  }
  ${HeadingCard} {
    padding: 2.5rem 1rem;
    background: ${props => props.theme.ui01};
  }
  ${media.greaterThan("414px")`
    ${HeadingCard} {
      > * {
        max-width: 70%;
      }
    }
  `}
  ${media.greaterThan("medium")`
    grid-template-rows: auto;
    padding-bottom: 5.5rem;
    ${HeadingCard} {
      padding: 4rem 0rem;
      > * {
        max-width: 38%;
      }
    }
    ${HeadingCard}, ${Banner} {
      grid-column: 1 / -1;
    }
  `}
`
